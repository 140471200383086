<!-- 体育分类的页面组件 -->
<template>
  <div class="common-layout">
    <el-container>
      <!-- 左侧 el-aside -->
      <el-aside class="left-aside" width="20%" v-show="showLeftAside">
        <visitedDu :allNum="visitTotalData.allNum" :todayNum="visitTotalData.todayNum" :weekData="visitTotalData.weekData"></visitedDu>
      </el-aside>
      <el-main>
        <mainDu style="display:flex;flex-direction: column;align-items: center;">
          <articleDu :articles="filteredArticlesByTag"></articleDu>
          <el-pagination
              :page-size="pageSize"
              :pager-count="5"
              layout="prev, pager, next"
              :total="totalArticlesByTag"
              @current-change="handlePageChange">
            </el-pagination>
        </mainDu>
      </el-main>
      <!-- 右侧 el-aside -->
      <el-aside class="right-aside" width="20%" v-show="showRightAside"></el-aside>
    </el-container>
  </div>
</template>

<script>
import mainDu from '@/components/mainDu.vue'
import articleDu from '@/components/articleDu.vue'
import visitedDu from '@/components/visitedDu.vue'
import {computed,onMounted,watch,ref} from 'vue'
import {useStore} from 'vuex'

export default {
    name:"pyhDu",
    components:{
        mainDu,
        articleDu,
        visitedDu
    },
    setup(){
      const store = useStore()
      // 设置一个页面显示的文章栏的数量
      const pageSize = 4
      // 初始化当前页码
      const currentPage = ref(1);

      const visitTotalData = computed(()=>store.getters['system/totalData'])
      // const category = 'pyh'
      onMounted(async()=>{
          try{
            // 获取指定分类的文章数据
            await store.dispatch('system/getVisitInfo')
            await store.dispatch('articles/loadFilteredArticlesByTag', '体育')
          }
          catch(error){
            console.error('未能获取到文章内容');

          }
        })
        // 获取数据之后处理过滤数据
      const filteredArticlesByTag = computed(() => store.getters['articles/filteredArticlesByTag']);
      // 获取指定分类下的文章总数
      const totalArticlesByTag = computed(()=> store.getters['articles/totalArticlesByTag'])
      // 处理页面切换方法
      const handlePageChange = (newPage) => {
        currentPage.value = newPage;
        store.commit('articles/SET_CURRENT_PAGE_BY_TAG', newPage); // 更新articles模块的currentPage状态
        store.dispatch('articles/loadFilteredArticlesByTag','体育'); // 重新加载文章数据
      };
      // 监视数据,实现更新
      watch(currentPage, (newPage) => {
        // 重新加载文章和留言数据
        store.commit('articles/SET_CURRENT_PAGE_BY_TAG', newPage);
        store.dispatch('articles/loadFilteredArticlesByTag','体育');
        store.dispatch('system/getVisitInfo')
      },
      {
        immediate:true
      });
      // 响应式设计相关
      const showLeftAside = computed(() => {
      // 当屏幕宽度小于等于 768px 时，隐藏左侧 el-aside
        return window.innerWidth > 768;
      });

      const showRightAside = computed(() => {
      // 当屏幕宽度小于等于 768px 时，隐藏右侧 el-aside
        return window.innerWidth > 768;
      });

    // 监听窗口大小变化，实时更新显示/隐藏状态
      window.addEventListener('resize', () => {
        showLeftAside.value = window.innerWidth > 768;
        showRightAside.value = window.innerWidth > 768;
      });
   
      return {
        filteredArticlesByTag,
        showLeftAside,
        showRightAside,
        totalArticlesByTag,
        pageSize,
        handlePageChange,
        visitTotalData
      }
    }
}
</script>
<style lang="scss" scoped>
.el-main{
  padding-top:0px ;
}
.el-aside {
  transition: all 0.3s;
}

@media (max-width: 768px) {
  /* 当屏幕宽度小于等于 768px 时，隐藏 el-aside */
  .el-aside {
    display: none;
  }
}
.el-pagination{
  --el-pagination-button-disabled-bg-color: none;
  --el-pagination-bg-color: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-aside, .right-aside {
  border: 1px solid #ccc; /* 添加一个细边框 */
  padding: 10px; /* 添加内边距 */
  background-color: #f0f0f0;
}
</style>
<style lang="scss">
  .el-pager li.is-active{
    color: #a2bd63;
  }
  .el-pager li:hover{
    color: #a2bd63;
  }
</style>